import { setSubscriptionPlan } from '@myci/domain-registration';
import { getIsUserTypeNumberCompany } from '@myci/domain-subscriptions';
import { useInstanceConfig } from '@myci/instances';
import { Message } from '@myci/intl';
import {
	PhoneInputFieldDeprecated,
	hasNoLeadingZero,
	isPossiblePhoneNumber,
} from '@myci/phone-numbers';
import { getCurrentInstanceEnv } from '@myci/utils';
import {
	PrivacyPolicyTextContainer,
	UserTypeSwitch,
	messages as registrationMessages,
} from '@myci/module-registration';
import { PasswordInputField, validatePasswordsConfirm } from '@myci/password';
import Recaptcha from 'react-google-recaptcha';
import { Box, Button, Grid, MandatoryFieldsText, Section, Textarea } from '@myci/ui-components';
import { Text } from '@ci/atoms';
import { CheckboxField, DatePickerField, Form, TextInputField } from '@myci/ui-components-redux';
import {
	isAdult,
	isIso8601Date,
	isValidYearOfEstablishment,
	validateEmailsMatch,
} from '@myci/validations';
import { combineValidate, validate } from '@validarium/core';
import {
	hasLength,
	hasLengthMax,
	hasLengthMin,
	isNumber,
	isRequired,
} from '@validarium/validations';
import { InjectedFormProps } from 'redux-form';
import { mergeDeepWithKey } from 'ramda';
import { alwaysNull } from 'ramda-extension';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPrivacyPolicy, selectPrivacyPolicy } from '@myci/domain-data-api';
import m from '../../../../messages';

const { recaptchaSiteKey, recaptchaTurnedOn } = getCurrentInstanceEnv();

const commonValidationFields = {
	agree: [isRequired],
	name: [isRequired, hasLengthMax(128)],
	phoneNumber: [isRequired, hasNoLeadingZero('ST'), isPossiblePhoneNumber],
	password: [isRequired],
	confirmPassword: [isRequired],
};

export const validateGeneralInfoIndividual = values => {
	const validariumValidations = combineValidate(
		validate({
			...commonValidationFields,
			surname: [isRequired, hasLengthMax(128)],
			dateOfBirth: [isRequired, isIso8601Date, isAdult()],
			idCardNumber: [isRequired, hasLengthMin(5), hasLengthMax(6)],
			taxNumber: [isRequired, hasLength(9)],
			placeOfBirth: [isRequired],
		}),
		validateEmailsMatch
	)(values);

	return mergeDeepWithKey(alwaysNull, validariumValidations, validatePasswordsConfirm(values));
};

export const validateGeneralInfoCompany = values => {
	const validariumValidations = combineValidate(
		validate({
			...commonValidationFields,
			registrationNumber: [isRequired, hasLength(9)],
			yearOfEstablishment: [isNumber, isValidYearOfEstablishment],
		}),
		validateEmailsMatch
	)(values);

	return mergeDeepWithKey(alwaysNull, validariumValidations, validatePasswordsConfirm(values));
};
interface GeneralInfoForProps extends InjectedFormProps<any> {
	onSubmit: (params: { form: string; values: Record<string, any> }) => void;
	userType: number;
}
export const GeneralInfoForm = ({
	form,
	handleSubmit,
	onSubmit,
	userType,
	pristine,
	invalid,
}: GeneralInfoForProps) => {
	const dispatch = useDispatch();
	const [isCaptchaVerified, setIsCaptchaVerified] = useState(!recaptchaTurnedOn);
	const [captchaResponse, setCaptchaResponse] = useState(null);
	const generalInfoFormSectionRef = useRef(null);
	const { defaultPhoneInputCountry } = useInstanceConfig();

	const isCompany = getIsUserTypeNumberCompany(userType);

	useEffect(() => {
		dispatch(setSubscriptionPlan({ productKey: isCompany ? 'company-free' : 'individual-free' }));
	}, []);

	useEffect(() => {
		dispatch(fetchPrivacyPolicy());
	}, []);

	const privacyPolicy = useSelector(selectPrivacyPolicy);

	const confirmCaptchaVerification = captchaResponse => {
		setIsCaptchaVerified(true);
		setCaptchaResponse(captchaResponse);
	};
	const isNextStepButtonDisabled = pristine || invalid || !isCaptchaVerified;
	return (
		<Form onSubmit={handleSubmit(values => onSubmit({ ...values, captchaResponse, form }))}>
			<Helmet>
				<script src="https://www.google.com/recaptcha/api.js" async defer />
			</Helmet>
			<Section py={4}>
				<Grid container>
					<Grid col={12} mb={{ xs: 3, lg: 4 }} className="text-center">
						<UserTypeSwitch />
					</Grid>
				</Grid>
			</Section>
			{
				<Box ref={generalInfoFormSectionRef}>
					<Box as="section" className="section--bordered" pt={3} pb={4}>
						<Grid container>
							<Grid row>
								<Grid col={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 1, lg: 2 }}>
									<Grid alignItems="center" mb={3}>
										<Text variant="tagline" icon="individual">
											<Message
												{...registrationMessages[isCompany ? 'companyInfo' : 'personalInfo']}
											/>
										</Text>
									</Grid>
									<Grid row>
										<Grid col={{ xs: 12, md: 6 }} mb={3}>
											<TextInputField
												label={
													isCompany ? (
														<Message {...registrationMessages.fieldCompanyName} />
													) : (
														<Message {...m.fieldName} />
													)
												}
												name="name"
												isRequired
											/>
										</Grid>
										{isCompany ? (
											<Grid col={{ xs: 12, md: 6 }} mb={{ xs: 3, md: 0 }}>
												<TextInputField
													label={<Message {...m.fieldRegistrationNumber} />}
													name="registrationNumber"
													isRequired
												/>
											</Grid>
										) : (
											<Grid col={{ xs: 12, md: 6 }} mb={3}>
												<TextInputField
													label={<Message {...registrationMessages.fieldSurname} />}
													name="surname"
													isRequired
												/>
											</Grid>
										)}

										{isCompany ? (
											<Fragment>
												<Grid col={{ xs: 12, md: 6 }}>
													<TextInputField
														label={<Message {...registrationMessages.yearOfEstablishment} />}
														name="yearOfEstablishment"
														type="number"
													/>
												</Grid>
												<Grid col={{ xs: 12, md: 6 }} mb={3}>
													<TextInputField
														label={<Message {...m.fieldCourtCode} />}
														name="courtCode"
														isRequired
													/>
												</Grid>
											</Fragment>
										) : (
											<Fragment>
												<Grid col={{ xs: 12, md: 6 }} mb={3}>
													<TextInputField
														label={<Message {...m.fieldIdCardNumber} />}
														name="idCardNumber"
														isRequired
													/>
												</Grid>
												<Grid col={{ xs: 12, md: 6 }}>
													<DatePickerField
														label={<Message {...registrationMessages.fieldDateOfBirth} />}
														name="dateOfBirth"
														isRequired
													/>
												</Grid>
												<Grid col={{ xs: 12, md: 6 }} mb={3}>
													<TextInputField
														label={<Message {...m.fieldTaxNumber} />}
														name="taxNumber"
														isRequired
													/>
												</Grid>
												<Grid col={{ xs: 12, md: 6 }} mb={3}>
													<TextInputField
														label={<Message {...m.fieldPlaceOfBirth} />}
														name="placeOfBirth"
														isRequired
													/>
												</Grid>
											</Fragment>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
					<Box as="section" className="section--bordered" py={4}>
						<Grid container>
							<Grid row>
								<Grid col={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 1, lg: 2 }}>
									<Grid alignItems="center" mb={3}>
										<Text variant="tagline" icon="loginInfo">
											<Message {...registrationMessages.loginInfo} />
										</Text>
									</Grid>
									<Grid row>
										<Grid col={{ xs: 12, md: 6 }} mb={3}>
											<TextInputField
												label={<Message {...registrationMessages.fieldEmail} />}
												name="email"
												type="email"
												warning={<Message {...registrationMessages.fieldEmailWarning} />}
												required
											/>
										</Grid>
										<Grid col={{ xs: 12, md: 6 }} mb={3}>
											<TextInputField
												label={<Message {...registrationMessages.fieldEmailConfirm} />}
												name="confirmEmail"
												type="email"
												required
											/>
										</Grid>
										<Grid col={{ xs: 12, md: 6 }} mb={3}>
											<PasswordInputField
												name="password"
												label={<Message {...registrationMessages.fieldPassword} />}
												isRequired
												hasRulesPopover
											/>
										</Grid>
										<Grid col={{ xs: 12, md: 6 }} mb={3}>
											<PasswordInputField
												name="confirmPassword"
												label={<Message {...registrationMessages.fieldPasswordConfirm} />}
												isRequired
											/>
										</Grid>
										<Grid col={{ xs: 12, md: 6 }} mb={{ xs: 3, md: 0 }}>
											<PhoneInputFieldDeprecated
												label={<Message {...registrationMessages.fieldPhoneNumber} />}
												name="phoneNumber"
												required
												defaultCountry={defaultPhoneInputCountry}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
					<Box
						as="section"
						className="section--terms position-relative"
						pt={4}
						pb={{ xs: 4, lg: 0 }}
					>
						<Grid container>
							<Grid row>
								<Grid col={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 1, lg: 2 }}>
									<Box mb={3}>
										{privacyPolicy?.data ? (
											<PrivacyPolicyTextContainer html={privacyPolicy.data} />
										) : (
											<Textarea
												className="text-body"
												label={<Message {...registrationMessages.fieldTermsConditions} />}
												labelProps={{ className: 'color-dark' }}
												name="terms-and-conditions"
												value={registrationMessages.termsConditions.defaultMessage}
												rows="10"
												readOnly
											/>
										)}
									</Box>
									<Box mb={3}>
										<CheckboxField
											label={<Message {...registrationMessages.confirmTermsConditions} />}
											name="agree"
											required
										/>
									</Box>
									{recaptchaTurnedOn && (
										<Box p={3} mb={3}>
											<Recaptcha
												sitekey={recaptchaSiteKey}
												onChange={captchaResponse => confirmCaptchaVerification(captchaResponse)}
											/>
										</Box>
									)}
									<MandatoryFieldsText mb={4} />
									<Grid flex>
										<Button href="/" kind="secondary" outline mr={3}>
											<Message {...registrationMessages.cancelButton} />
										</Button>
										<Button kind="primary" type="submit" disabled={isNextStepButtonDisabled}>
											<Message {...registrationMessages.continueButton} />
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Box>
			}
		</Form>
	);
};
