import React from 'react';
import { Box, Button, Grid, Heading } from '@myci/ui-components';
import { Icon, Text } from '@ci/atoms';
import { Message } from '@myci/intl';
import { useDispatch } from 'react-redux';
import { navigate } from '@gatsbyjs/reach-router';
import { setSubscriptionPlan } from '@myci/domain-registration';
import { messages as m } from '@myci/module-registration';
import { prepareStyle } from '@ci/styles';

const descriptionStyle = prepareStyle(utils => ({
	marginBottom: utils.spacings.md,
}));

const iconStyle = prepareStyle(utils => ({
	marginBottom: utils.spacings.lg,
}));

export const Success = () => {
	const dispatch = useDispatch();

	return (
		<Box as="section" py={4}>
			<Grid container>
				<Grid row>
					<Grid col={12} flexDirection="column" alignItems="center">
						<Icon type="addressPhysical" color="teal" size="xxl" customStyle={iconStyle} />
						<Heading as="h1" mb={4}>
							<Message {...m.almostDone} />
						</Heading>
						<Text customStyle={descriptionStyle}>
							<Message {...m.almostDoneDescription} />
						</Text>
					</Grid>
				</Grid>
				<Grid row justifyContent="center">
					<Button
						kind="info"
						outline
						onClick={() => {
							dispatch(setSubscriptionPlan(null));
							navigate('/');
						}}
					>
						<Message {...m.goToHomepage} />
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};
