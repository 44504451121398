import React from 'react';
import { Button, Text } from '@ci/atoms';
import { Box, Grid } from '@myci/ui-components';
import { FileInputField, Form, TextInputField } from '@myci/ui-components-redux';
import { Message } from '@myci/intl';
import { isPendingRegistration } from '@myci/domain-registration';
import { USER_TYPE } from '@myci/domain-subscriptions';
import { hasLengthMax, isRequired } from '@validarium/validations';
import { validate } from '@validarium/core';
import { filter, includes, keys } from 'ramda';
import { CountrySelectField, messages as registrationMessages } from '@myci/module-registration';
import { useSelector } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
import m from '../../../../messages';

const commonValidationFields = {
	country: [isRequired],
	district: [isRequired],
	region: [isRequired],
	street: [isRequired, hasLengthMax(64)],
};

export const validatePersonalInfoIndividual = validate({
	...commonValidationFields,
	idCardScan: [isRequired],
	idCardScan_sideB: [isRequired],
	zipCode: [isRequired, hasLengthMax(64)],
});

export const validatePersonalInfoCompany = values =>
	validate({
		...commonValidationFields,
	})(values);

interface PersonalInfoFormProps extends InjectedFormProps<any> {
	allowedCountries: Record<number, string>;
	userType: number;
}

export const PersonalInfoForm = ({
	allowedCountries,
	handleSubmit,
	userType,
	pristine,
	invalid,
}: PersonalInfoFormProps) => {
	const isCompany = userType === USER_TYPE.Company;
	const isSubmitting: boolean = useSelector(isPendingRegistration);

	return (
		<Form onSubmit={handleSubmit}>
			<Box as="section" className="section--bordered" py={4}>
				<Grid container>
					<Grid row>
						<Grid col={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 1, lg: 2 }}>
							<Grid alignItems="center" mb={3}>
								<Text variant="tagline" icon="individual">
									<Message {...registrationMessages.personalInfo} />
								</Text>
							</Grid>
							<Grid row>
								<Grid col={{ xs: 12, md: 6 }} mb={3}>
									<CountrySelectField
										formValueProp="key"
										filterItems={filter((country: Record<string, any>) =>
											includes(country.key.toString(), keys(allowedCountries))
										)}
									/>
								</Grid>
								<Grid col={{ xs: 12, md: 6 }} mb={3}>
									<TextInputField
										label={<Message {...registrationMessages.fieldCity} />}
										name="city"
										isRequired
									/>
								</Grid>
								<Grid col={{ xs: 12, md: 6 }} mb={3}>
									<TextInputField
										label={<Message {...m.fieldProvince} />}
										name="province"
										isRequired
									/>
								</Grid>
								<Grid col={{ xs: 12, md: 6 }} mb={3}>
									<TextInputField
										label={<Message {...m.fieldDistrict} />}
										name="district"
										isRequired
									/>
								</Grid>
								<Grid col={{ xs: 12, md: 6 }} mb={3}>
									<TextInputField label={<Message {...m.fieldRegion} />} name="region" isRequired />
								</Grid>
								<Grid col={{ xs: 12, md: 6 }} mb={3}>
									<TextInputField
										label={<Message {...registrationMessages.fieldStreet} />}
										name="street"
										isRequired
									/>
								</Grid>
								{!isCompany && (
									<Grid col={{ xs: 12, md: 6 }} mb={3}>
										<TextInputField
											label={<Message {...m.fieldZipCode} />}
											name="zipCode"
											isRequired
										/>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<Box as="section" py={4}>
				<Grid container>
					<Grid row>
						<Grid col={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 1, lg: 2 }}>
							{!isCompany && (
								<Grid alignItems="center" mb={3}>
									<Text variant="tagline">
										<Message {...registrationMessages.scanId} />
									</Text>
								</Grid>
							)}
							{!isCompany && (
								<Grid row>
									<Grid col={{ xs: 12, md: 6 }} mb={{ xs: 3, md: 0 }}>
										<FileInputField
											name="idCardScan"
											label={<Message {...registrationMessages.fieldAttachment} />}
											defaultFileName="ID_Front"
											isRequired
										/>
									</Grid>
									<Grid col={{ xs: 12, md: 6 }} mb={{ xs: 3, md: 0 }}>
										<FileInputField
											name="idCardScan_sideB"
											label={<Message {...registrationMessages.fieldAttachmentSideB} />}
											defaultFileName="ID_Back"
											isRequired
										/>
									</Grid>
								</Grid>
							)}
							<Grid mb={{ xs: 2, lg: 0 }} mt={5}>
								<Button type="submit" isDisabled={pristine || invalid} isBusy={isSubmitting}>
									<Message {...registrationMessages.registerButton} />
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Form>
	);
};
