import React, { useEffect } from 'react';
import { Box, Button, Grid } from '@myci/ui-components';
import { Form, TextInputField } from '@myci/ui-components-redux';
import { Message } from '@myci/intl';
import { isRequired } from '@validarium/validations';
import { validate } from '@validarium/core';
import {
	forms,
	getCaptchaResponse,
	makeSelectEmailAddress,
	makeSelectPhoneNumber,
	requestPhoneCode,
	resendEmailCode,
	selectIsEmailVerified,
	selectIsPhoneVerificationSent,
} from '@myci/domain-registration';
import { useDispatch, useSelector } from 'react-redux';
import { VerifyContact, messages as registrationMessages } from '@myci/module-registration';
import { InjectedFormProps } from 'redux-form';

export const validateVerifyEmail = validate({
	code: [isRequired],
});

interface VerifyEmailFormProps extends InjectedFormProps<any> {
	goToPreviousStep: (...args: any[]) => any;
}
export const VerifyEmailForm = ({ goToPreviousStep, handleSubmit }: VerifyEmailFormProps) => {
	const dispatch = useDispatch();
	const email = useSelector(makeSelectEmailAddress(forms.registration));
	const phoneNumber = useSelector(makeSelectPhoneNumber(forms.registration));
	const captchaResponse = useSelector(getCaptchaResponse);
	const isEmailVerified = useSelector(selectIsEmailVerified);
	const isPhoneVerificationSent = useSelector(selectIsPhoneVerificationSent);

	useEffect(() => {
		if (!isPhoneVerificationSent && isEmailVerified) {
			dispatch(
				requestPhoneCode({ phoneNumber, captchaResponse, meta: { namespace: forms.registration } })
			);
		}
	}, [isEmailVerified]);

	const handleResendEmailCode = ({ email }) =>
		dispatch(resendEmailCode({ email, meta: { namespace: forms.registration } }));

	return (
		<Form onSubmit={handleSubmit}>
			<Box as="section" className="section--bordered" py={4}>
				<Grid container>
					<Grid row>
						<Grid col={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 1, lg: 2 }}>
							<Grid row>
								<Grid col={{ xs: 12, md: 6 }} mb={3}>
									<VerifyContact
										field={
											<TextInputField
												label={<Message {...registrationMessages.verificationCode} />}
												name="code"
												isRequired
											/>
										}
										note={<Message {...registrationMessages.verificationCodeExplanation} />}
										onClick={() => handleResendEmailCode({ email })}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<Box as="section" py={4}>
				<Grid container>
					<Grid row>
						<Grid col={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 1, lg: 2 }}>
							<Button kind="secondary" onClick={goToPreviousStep} outline mr={3}>
								<Message {...registrationMessages.backButton} />
							</Button>
							<Button kind="primary" type="submit">
								<Message {...registrationMessages.nextButton} />
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Form>
	);
};
