import Payment from '@myci/module-registration/Payment';
import { combineValidate } from '@validarium/core';
import { useMemo } from 'react';
import { PAYMENT_GATEWAY_METHOD } from '../../constants';

import {
	GeneralInfoForm,
	validateGeneralInfoCompany,
	validateGeneralInfoIndividual,
} from './components/journey/GeneralInfoForm';
import {
	PersonalInfoForm,
	validatePersonalInfoCompany,
	validatePersonalInfoIndividual,
} from './components/journey/PersonalInfoForm';
import { Success } from './components/journey/Success';
import { VerifyEmailForm, validateVerifyEmail } from './components/journey/VerifyEmailForm';
import { VerifyPhoneForm, validateVerifyPhoneCode } from './components/journey/VerifyPhoneForm';

export const useMyCiRegistrationConfig = () =>
	useMemo(
		() => ({
			registrationJourney: {
				generalInfoForm: GeneralInfoForm,
				payment: Payment,
				personalInfoForm: PersonalInfoForm,
				verifyEmailForm: VerifyEmailForm,
				verifyPhoneForm: VerifyPhoneForm,
				success: Success,
			},
			registrationJourneyValidateIndividual: combineValidate(
				validateGeneralInfoIndividual,
				validatePersonalInfoIndividual,
				validateVerifyPhoneCode,
				validateVerifyEmail
			),
			registrationJourneyValidateCompany: combineValidate(
				validateGeneralInfoCompany,
				validatePersonalInfoCompany,
				validateVerifyPhoneCode,
				validateVerifyEmail
			),
			registrationJourneyAsyncBlurFields: ['email', 'password', 'phoneNumber'],
			registrationJourneyAllowedCountries: {
				199: 'Sao Tome and Principe',
			},
			paymentGatewayMethod: PAYMENT_GATEWAY_METHOD,
		}),
		[]
	);
