import React from 'react';
import { Box, Button, Grid } from '@myci/ui-components';
import { Form, TextInputField } from '@myci/ui-components-redux';
import { Message } from '@myci/intl';
import { isRequired } from '@validarium/validations';
import { validate } from '@validarium/core';
import { forms, makeSelectPhoneNumber, resendPhoneCode } from '@myci/domain-registration';
import { useDispatch, useSelector } from 'react-redux';
import { VerifyContact, messages as registrationMessages } from '@myci/module-registration';
import { InjectedFormProps } from 'redux-form';

export const validateVerifyPhoneCode = validate({
	codePhoneNumber: [isRequired],
});
interface VerifyPhoneFormProps extends InjectedFormProps<any> {
	goToPreviousStep: (...args: any[]) => any;
}
export const VerifyPhoneForm = ({ goToPreviousStep, handleSubmit }: VerifyPhoneFormProps) => {
	const dispatch = useDispatch();
	const phoneNumber = useSelector(makeSelectPhoneNumber(forms.registration));

	const handleResendPhoneCode = ({ phoneNumber }) =>
		dispatch(resendPhoneCode({ phoneNumber, meta: { namespace: forms.registration } }));

	return (
		<Form onSubmit={handleSubmit}>
			<Box as="section" className="section--bordered" py={4}>
				<Grid container>
					<Grid row>
						<Grid col={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 1, lg: 2 }}>
							<Grid row>
								<Grid col={{ xs: 12, md: 6 }} mb={3}>
									<VerifyContact
										field={
											<TextInputField
												label={<Message {...registrationMessages.confirmationSmsCode} />}
												name="codePhoneNumber"
												isRequired
											/>
										}
										note={<Message {...registrationMessages.confirmationSmsCodeExplanation} />}
										onClick={() => handleResendPhoneCode({ phoneNumber })}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<Box as="section" py={4}>
				<Grid container>
					<Grid row>
						<Grid col={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 1, lg: 2 }}>
							<Button kind="secondary" onClick={goToPreviousStep} outline mr={3}>
								<Message {...registrationMessages.backButton} />
							</Button>
							<Button kind="primary" type="submit">
								<Message {...registrationMessages.nextButton} />
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Form>
	);
};
